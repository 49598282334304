<template>
  <b-row>
    <b-col v-if="!showForm && !showFormLegado" cols="12">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-2">
          <b-card-header header-tag="header" class="p-1" role="tab">
            Filtros
          </b-card-header>
          <b-collapse
            id="accordion-1"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text>
                <b-row>
                  <b-col cols="12" sm="6" lg="3">
                    <b-form-group
                      label="Periodo Inicial"
                      label-for="v-dataInicio"
                    >
                      <flat-pickr
                        v-model="filter.dataInicio"
                        name="fieldDate"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'd/m/Y',
                          enableTime: false,
                          dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6" lg="3">
                    <b-form-group label="Periodo Final" label-for="v-dataFim">
                      <flat-pickr
                        v-model="filter.dataFim"
                        name="fieldDate"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'd/m/Y',
                          enableTime: false,
                          dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                        }"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="6" lg="3">
                    <b-form-group label="Número" label-for="v-numero">
                      <b-form-input
                        id="v-numero"
                        v-model="filter.numero"
                        placeholder="Número"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="6" lg="3">
                    <b-form-group label="Situação" label-for="v-situacao">
                      <v-select
                        v-model="filter.situacao"
                        :reduce="(comboSituacao) => comboSituacao.id"
                        label="nome"
                        :options="comboSituacao"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" sm="6">
                    <b-form-group
                      label="Nome Profissional"
                      label-for="v-nomeProfissional"
                    >
                      <b-form-input
                        id="v-nomeProfissional"
                        v-model="filter.nomeProfissional"
                        placeholder="nome do profissional"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <b-form-group
                      label="Nome Soliciante"
                      label-for="v-nomeSolicitante"
                    >
                      <b-form-input
                        id="v-nomeSolicitante"
                        v-model="filter.nomeSolicitante"
                        placeholder="nome do solicitante"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="filtrar"
                    >
                      {{ loadingFiltro ? 'Aguarde...' : 'Filtrar' }}
                      <b-spinner v-if="loadingFiltro" small></b-spinner>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="warning"
                      @click="limpar"
                    >
                      Limpar
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-col>
    <b-col cols="12">
      <b-card v-if="!showForm && !showFormLegado" title="Atendimentos">
        <div class="custom-search d-flex justify-content-start">
          <b-button variant="primary" @click="addNew()"> Incluir </b-button>
          <b-button
            variant="success"
            class="ml-1"
            data-cy="inserirLegado"
            @click="inserirLegado"
          >
            Inserir Atendimento Legado
          </b-button>
        </div>

        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="listaAtendimentos"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="edit(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="remove(props.row)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="tableConfigs.size"
                  :options="[tableConfigs.size]"
                  class="mx-1"
                  @input="handleChangePage"
                />
                <span class="text-nowrap">
                  of {{ tableConfigs.totalPages }} entries
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="tableConfigs.totalElements"
                  :per-page="tableConfigs.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <AttendanceForm v-if="showForm" @cancelar="cancelar" />
      <div v-if="showFormLegado">
        <AtendimentoLegadoForm @cancelarLegado="cancelarLegado" />
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BCardText,
  BFormGroup,
  BFormInput,
  BSpinner,
  BFormSelect,
  BPagination,
  BDropdown,
  BDropdownItem
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import AttendanceForm from '../form/AttendanceForm.vue'
import AtendimentoLegadoForm from '../../atendimentoLegado/AtendimentoLegadoForm.vue'
import Ripple from 'vue-ripple-directive'
import mixinsGlobal from '@/mixins'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import _ from 'lodash'

export default {
  name: 'Atendimento',
  directives: {
    Ripple
  },
  components: {
    BRow,
    BCol,
    TableComponents,
    AttendanceForm,
    BButton,
    AtendimentoLegadoForm,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BCardText,
    BFormGroup,
    BFormInput,
    flatPickr,
    BSpinner,
    vSelect,
    VueGoodTable,
    BFormSelect,
    BPagination,
    BDropdown,
    BDropdownItem
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('atendimentoModule', {
      listaAtendimentos: (state) => state.listaAtendimentos
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  data() {
    return {
      comboSituacao: [],
      loadingFiltro: false,
      searchTerm: '',
      pageLength: 10,
      totalRecords: 0,
      isLoading: false,
      tableConfigs: {
        last: false,
        totalPages: 5,
        totalElements: 41,
        size: 10,
        number: 0
      },
      filter: {
        dataInicio: '',
        dataFim: '',
        nomeProfissional: '',
        nomeSolicitante: '',
        situacao: '',
        numero: ''
      },
      ehAssociado: false,
      showCpfAssociado: true,
      showForm: false,
      columns: [
        {
          label: 'Nº Atendimento',
          field: 'numeroAtendimento'
        },
        {
          label: 'Data Atendimento',
          field: 'dataAtendimento',
          formatFn: this.formatDateGrid
        },
        {
          label: 'Hora',
          field: 'horaAgenda'
        },
        {
          label: 'Profissional',
          field: 'profissional.nome',
          sortable: true
        },
        {
          label: 'Nome Solicitante',
          field: 'registro.nome',
          sortable: true
        },
        {
          label: 'Situação',
          field: 'situacao.descricao',
          sortable: false,
          tdClass: this.tdClassFunc
        },

        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageConfig: {
        page: 0,
        size: 10,
        sort: 'nome',
        order: 'asc'
      },
      message: 'Atualizando a cada 3 minutos',
      intervalId: null,
      showFormLegado: false
    }
  },
  async mounted() {
    this.isLoading = true
    this.loadingFiltro = true
    const date = new Date()
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const firstDayDate = firstDay.toISOString().split('T')[0]

    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const lastDayDate = lastDay.toISOString().split('T')[0]

    this.filter = {
      dataInicio: firstDayDate,
      dataFim: lastDayDate,
      nomeProfissional: '',
      nomeSolicitante: '',
      situacao: '',
      numero: ''
    }

    const [year, month, day] = firstDayDate.split('-')
    const dataInicio = `${day}-${month}-${year}`

    const [lastYear, LastMonth, LastDay] = lastDayDate.split('-')
    const dataFim = `${LastDay}-${LastMonth}-${lastYear}`

    const filtro = {
      dataInicio: dataInicio,
      dataFim: dataFim,
      nomeProfissional: '',
      nomeSolicitante: '',
      situacao: '',
      numero: ''
    }

    const obj = {
      filter: filtro,
      pageConfig: this.pageConfig
    }
    const result = await this.$store.dispatch('atendimentoModule/filtro', obj)
    this.pageConfig.page = result.data.pageable.pageNumber
    this.tableConfigs.last = result.data.last
    this.tableConfigs.totalPages = result.data.totalPages
    this.tableConfigs.totalElements = result.data.totalElements
    this.tableConfigs.size = result.data.size
    this.tableConfigs.number = result.data.number
    this.isLoading = false
    this.loadingFiltro = false
    await this.loadComboSituacao()
    await this.startAutoUpdate()
  },
  beforeRouteLeave(to, from, next) {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
    next()
  },
  methods: {
    pageConfigdefault() {
      this.pageConfig = {
        check: false,
        page: 0,
        size: 10,
        sort: 'dataInicio',
        order: 'asc'
      }
    },
    onSortChange(params) {
      this.pageConfigdefault()
      this.pageConfig.sort = params[0].field
      this.pageConfig.order = params[0].type
      this.filtrar()
    },
    handleChangePage(page) {
      this.pageConfig.page = page
      this.filtrar()
    },
    async loadComboSituacao() {
      this.$store
        .dispatch('combosModule/loadComboSituacao')
        .then((response) => {
          this.comboSituacao = response.data.map((item) => ({
            id: item.id,
            nome: item.descricao
          }))
        })
    },
    async filtrar() {
      const filter = { ...this.filter }

      if (filter.dataInicio) {
        const [year, month, day] = filter.dataInicio.split('T')[0].split('-')
        filter.dataInicio = `${day}-${month}-${year}`
      }

      if (filter.dataFim) {
        const [year, month, day] = filter.dataFim.split('T')[0].split('-')
        filter.dataFim = `${day}-${month}-${year}`
      }

      this.loadTable({
        filter,
        pageConfig: this.pageConfig
      })
    },
    limpar() {
      const date = new Date()
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
      const firstDayDate = firstDay.toISOString().split('T')[0]

      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      const lastDayDate = lastDay.toISOString().split('T')[0]
      this.filter = {
        dataInicio: firstDayDate,
        dataFim: lastDayDate,
        nomeProfissional: '',
        nomeSolicitante: '',
        situacao: '',
        numero: ''
      }

      const [year, month, day] = firstDayDate.split('-')
      const dataInicio = `${day}-${month}-${year}`

      const [lastYear, LastMonth, LastDay] = lastDayDate.split('-')
      const dataFim = `${LastDay}-${LastMonth}-${lastYear}`

      const filtro = {
        dataInicio: dataInicio,
        dataFim: dataFim,
        nomeProfissional: '',
        nomeSolicitante: '',
        situacao: '',
        numero: ''
      }
      this.loadTable({
        filter: filtro,
        pageConfig: this.pageConfig
      })
      this.startAutoUpdate()
    },
    inserirLegado() {
      this.showFormLegado = true
    },
    startAutoUpdate() {
      this.intervalId = setInterval(() => {
        this.filtrar()
        this.isLoading = false
        this.loadingFiltro = false
      }, 300000)
      // 300000 Inicia o setInterval para atualizar a cada 3 minutos (180000 ms)
    },
    stopAutoUpdate() {
      // Limpa o intervalo quando o componente é desmontado
      clearInterval(this.intervalId)
    },
    async addNew() {
      await this.$store.dispatch('atendimentoModule/reset')
      this.showForm = true
    },
    async edit(data) {
      this.$router.push({ path: `editAtendimento/${data.id}` })
    },
    async cancelar() {
      this.showForm = false
    },
    cancelarLegado() {
      this.showFormLegado = false
    },
    async loadTable(obj) {
      this.isLoading = true
      this.loadingFiltro = true
      const result = await this.$store.dispatch('atendimentoModule/filtro', obj)
      this.pageConfig.page = result.data.pageable.pageNumber
      this.tableConfigs.last = result.data.last
      this.tableConfigs.totalPages = result.data.totalPages
      this.tableConfigs.totalElements = result.data.totalElements
      this.tableConfigs.size = result.data.size
      this.tableConfigs.number = result.data.number
      this.isLoading = false
      this.loadingFiltro = false
    },
    async remove(data) {
      this.$swal({
        title: 'Remover o registro?',
        text: 'Está ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('atendimentoModule/delete', data.id)
            .then((response) => {
              const date = new Date()
              const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
              const firstDayDate = firstDay.toISOString().split('T')[0]

              const lastDay = new Date(
                date.getFullYear(),
                date.getMonth() + 1,
                0
              )
              const lastDayDate = lastDay.toISOString().split('T')[0]

              this.filter = {
                dataInicio: firstDayDate,
                dataFim: lastDayDate,
                nomeProfissional: '',
                nomeSolicitante: '',
                situacao: '',
                numero: ''
              }

              const [year, month, day] = firstDayDate.split('-')
              const dataInicio = `${day}-${month}-${year}`

              const [lastYear, LastMonth, LastDay] = lastDayDate.split('-')
              const dataFim = `${LastDay}-${LastMonth}-${lastYear}`

              const filtro = {
                dataInicio: dataInicio,
                dataFim: dataFim,
                nomeProfissional: '',
                nomeSolicitante: '',
                situacao: '',
                numero: ''
              }
              if (response.data.error) {
                this.MensagemError(
                  'Falha ao remover o registro',
                  response.data.message
                )
                return
              }
              this.showMessageSuccess(
                'Remover',
                'Registro removido com sucesso!'
              )
              this.pageConfig.sort = 'descricao'
              const obj = {
                filter: filtro,
                pageConfig: this.pageConfig
              }
              this.loadTable(obj)
            })
            .catch((err) => {
              this.MensagemError('Falha ao remover o registro', err.message)
              this.pageConfig.sort = 'descricao'
              const obj = {
                filter: this.filter,
                pageConfig: this.pageConfig
              }
              this.loadTable(obj)
            })
        }
      })
    },
    tdClassFunc(row) {
      if (row.situacao && row.situacao.descricao) {
        const status = row.situacao.descricao.toLowerCase()
        switch (status) {
          case 'aguardando':
            return 'text-primary'
          case 'em atendimento':
            return 'text-info'
          case 'finalizado':
            return 'text-success'
          case 'cancelado':
            return 'text-danger'
          case 'pendente':
            return 'text-warning'
          default:
            return ''
        }
      }
    }
  }
}
</script>
